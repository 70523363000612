'use client';
import * as Sentry from '@sentry/nextjs';
import React from 'react';
import { appPublicConfig } from '@/configs/appPublicConfig';
import { CustomErrorPage } from '@/shared/appRouter/components/pages/CustomErrorPage';

export default function RootError({
  error,
}: {
  error: Error & {
    digest?: string;
  };
}) {
  React.useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <CustomErrorPage config={appPublicConfig} statusCode={500} />;
}
